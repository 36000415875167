const HEALTH_YOGA_PRODUCTS=[

    {
        ProductName: "Enema Kits",
        ProductImage: require('../Assets/Products/HEALTH & YOGA PRODUCTS/enema-products.jpg')
    },
    {
        ProductName: "Neti Pot",
        ProductImage: require('../Assets/Products/HEALTH & YOGA PRODUCTS/jala-neti.jpg')
    }, {
        ProductName: "Neti Salt",
        ProductImage: require('../Assets/Products/HEALTH & YOGA PRODUCTS/neti-salt.jpg')
    }, {
        ProductName: "Epsom Salt",
        ProductImage: require('../Assets/Products/HEALTH & YOGA PRODUCTS/epsom-salt.jpg')
    }, {
        ProductName: "Sitz Bath Tub",
        ProductImage: require('../Assets/Products/HEALTH & YOGA PRODUCTS/sitz-bath-tub.jpg')
    }, {
        ProductName: "Enema Syringe & Douche Bulb",
        ProductImage: require('../Assets/Products/HEALTH & YOGA PRODUCTS/enema-syringe-and-douche-bulb.jpg')
    }, {
        ProductName: "Enema Accessories",
        ProductImage: require('../Assets/Products/HEALTH & YOGA PRODUCTS/enema-accessories.jpg')
    },

]

export default HEALTH_YOGA_PRODUCTS;