const LABORATORY_PLASTICWARE=[
    {
        ProductName: "Plastic Lab Beakers",
        ProductImage: require('../Assets/Products/LABORATORY PLASTICWARE/plastic-lab-beakers.jpg')
    },
    {
        ProductName: "Plastic Lab Bottles",
        ProductImage: require('../Assets/Products/LABORATORY PLASTICWARE/plastic-lab-bottles.jpg')
    }, {
        ProductName: "Plastic Stopcocks & Connectors",
        ProductImage: require('../Assets/Products/LABORATORY PLASTICWARE/stop-cocks.jpg')
    }, {
        ProductName: "Plastic Funnels & Holders",
        ProductImage: require('../Assets/Products/LABORATORY PLASTICWARE/funnels.jpg')
    }, {
        ProductName: "Plastic Petri Dishes",
        ProductImage: require('../Assets/Products/LABORATORY PLASTICWARE/petri-dishes.jpg')
    }, {
        ProductName: "Slide Storage Boxes and Accessories",
        ProductImage: require('../Assets/Products/LABORATORY PLASTICWARE/slide-accesories.jpg')
    }, {
        ProductName: "Plastic Centrifuge Tube",
        ProductImage: require('../Assets/Products/LABORATORY PLASTICWARE/tubes.jpg')
    }, {
        ProductName: "Plastic Lab Containers",
        ProductImage: require('../Assets/Products/LABORATORY PLASTICWARE/plastic-lab-containers.jpg')
    }, {
        ProductName: "Plastic Lab Vials",
        ProductImage: require('../Assets/Products/LABORATORY PLASTICWARE/plastic-lab-vials.jpg')
    }, {
        ProductName: "Plastic Measuring Cylinders",
        ProductImage: require('../Assets/Products/LABORATORY PLASTICWARE/plastic-lab-measuring-cylinders.jpg')
    }, {
        ProductName: "Plastic Measuring Jugs",
        ProductImage: require('../Assets/Products/LABORATORY PLASTICWARE/plastic-measuring-jugs.jpg')
    }, {
        ProductName: "Conical Measures",
        ProductImage: require('../Assets/Products/LABORATORY PLASTICWARE/conical-measures.jpg')
    }, {
        ProductName: "Plastic Atomic Models",
        ProductImage: require('../Assets/Products/LABORATORY PLASTICWARE/plastic-atomic-models.jpg')
    }, {
        ProductName: "Pipette Accessories",
        ProductImage: require('../Assets/Products/LABORATORY PLASTICWARE/pipette-accessories.jpg')
    }, {
        ProductName: "Microcentrifuge Tubes",
        ProductImage: require('../Assets/Products/LABORATORY PLASTICWARE/microcentrifuge-tubes.jpg')
    }, {
        ProductName: "Micropipette & Accessories",
        ProductImage: require('../Assets/Products/LABORATORY PLASTICWARE/micropipette-and-accessories.jpg')
    }, {
        ProductName: "Racks & Stands",
        ProductImage: require('../Assets/Products/LABORATORY PLASTICWARE/racks-and-stands.jpg')
    }, {
        ProductName: "General Labware",
        ProductImage: require('../Assets/Products/LABORATORY PLASTICWARE/general-labware.jpg')
    },
]


export default LABORATORY_PLASTICWARE;