const HEIGHT_WEIGHT_SCALES=[
    {
        ProductName: "Stadiometer/Height Measuring Scale",
        ProductImage: require('../Assets/Products/HEIGHT & WEIGHT SCALES/height-measuring-scales.jpg')
    },
    {
        ProductName: "Measuring Tape",
        ProductImage: require('../Assets/Products/HEIGHT & WEIGHT SCALES/measuring-tape.jpg')
    }, {
        ProductName: "Infantometer",
        ProductImage: require('../Assets/Products/HEIGHT & WEIGHT SCALES/infantometer.jpg')
    }, {
        ProductName: "Personal Weighing Scale",
        ProductImage: require('../Assets/Products/HEIGHT & WEIGHT SCALES/personal-weighing-scale.jpg')
    }, {
        ProductName: "Baby Weighing Scales",
        ProductImage: require('../Assets/Products/HEIGHT & WEIGHT SCALES/baby-weighing-scales.jpg')
    }, {
        ProductName: "Stature Meter",
        ProductImage: require('../Assets/Products/HEIGHT & WEIGHT SCALES/stature-meter.jpg')
    }
]

export default HEIGHT_WEIGHT_SCALES;