const SURGICAL_INSTRUMENTS=[
{
    ProductName: "Instrument Kit",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/instrument-kit.jpg')
},
{
    ProductName: "Forceps",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS//forceps.jpg')
},{
    ProductName: "General Dissecting Forceps",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/general-dissecting-forceps.jpg')
},{
    ProductName: "Scissors",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/scissors.jpg')
},{
    ProductName: "Scalpel Handle",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/scalpels.jpg')
},{
    ProductName: "Other Instruments",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/other-instruments.jpg')
},{
    ProductName: "OB/GYN",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/ob-gyn.jpg')
},{
    ProductName: "Tracheal Retractors",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/tracheal-retractors.jpg')
},{
    ProductName: "Rectal Speculum",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/rectal-speculum.jpg')
},{
    ProductName: "Abdominal Retractors",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/abdominal-retractors.jpg')
},{
    ProductName: "Volkman Retractor",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/finger-retractors.jpg')
},{
    ProductName: "General Use Retractors",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/general-use-retractors.jpg')
},{
    ProductName: "Mouth Gags",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/mouth-gags.jpg')
},{
    ProductName: "Self-Retaining Retractors",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/surgical-retractors.jpg')
},{
    ProductName: "ENT Instruments",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/ent-instruments.jpg')
},{
    ProductName: "Suction Tube",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/suction-tube.jpg')
},{
    ProductName: "Surgical Cannula",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/surgical-cannula.jpg')
},
{
    ProductName: "Nuero Instruments",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/nuero-instruments.jpg')
},
{
    ProductName: "Cardiothoracic Instruments",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/cardiothoracic-instruments.jpg')
},
{
    ProductName: "Ophthalmic Instruments",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/ophthalmic-instruments.jpg')
},
{
    ProductName: "Veterinary Instruments",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/veterinary-instruments.jpg')
},
{
    ProductName: "Spine Surgical Instruments",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/spine-surgical-instruments.jpg')
},
{
    ProductName: "Laparoscopic Instruments",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/laparoscopy-instruments.jpg')
},
{
    ProductName: "Nasal Speculum",
    ProductImage: require('../Assets/Products/SURGICAL INSTRUMENTS/nasal-speculum.jpg')
},


]

export default SURGICAL_INSTRUMENTS;