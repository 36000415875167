import React from 'react'
import './Footer.css'
import { Helmet } from 'react-helmet';
import logo from "../Assets/Logo.jpg"

export default function Footer() {
  return (
    <div className='footer'>
  <Helmet>
        <title>Guru Surgicals | Manufacture And Supply Surgical Products</title>
        <meta name="description" content="Guru Surgicals is a trusted provider of high-quality surgical instruments and medical equipment. We offer a wide range of surgical instruments for various medical procedures, including general surgery, orthopedics, gynecology, and more. Our products are designed to meet the needs of healthcare professionals, providing precision, durability, and reliability. Visit us for all your surgical instrument needs." />
        <meta name="keywords" content="surgical instruments, medical equipment, high-quality surgical instruments, surgical instruments supplier, surgical equipment, general surgery instruments, orthopedic instruments, gynecology instruments, healthcare instruments, medical instrument provider" />
        <meta name="author" content="Rithish Abinav T" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        
        {/* Open Graph meta tags (for social media sharing) */}
        <meta property="og:title" content="Guru Surgicals | Manufacture And Supply Surgical Products" />
        <meta property="og:description" content="Guru Surgicals is a trusted provider of high-quality surgical instruments and medical equipment. We offer a wide range of surgical instruments for various medical procedures, including general surgery, orthopedics, gynecology, and more. Our products are designed to meet the needs of healthcare professionals, providing precision, durability, and reliability. Visit us for all your surgical instrument needs." />
        <meta property="og:image" content='http://gurusurgicals.com/static/media/Logo.9c55885c8bc49c394674.jpeg' />
        <meta property="og:url" content='http://gurusurgicals.com/' />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card meta tags (for Twitter sharing) */}
        <meta name="twitter:title" content="Guru Surgicals | Manufacture And Supply Surgical Products" />
        <meta name="twitter:description" content="Guru Surgicals is a trusted provider of high-quality surgical instruments and medical equipment. We offer a wide range of surgical instruments for various medical procedures, including general surgery, orthopedics, gynecology, and more. Our products are designed to meet the needs of healthcare professionals, providing precision, durability, and reliability. Visit us for all your surgical instrument needs." />
        <meta name="twitter:image" content='http://gurusurgicals.com/static/media/Logo.9c55885c8bc49c394674.jpeg' />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="for_seo">
        <h1>Guru Surgicals</h1>
        <h1>Guru Group</h1>
        <h1>Guru Integrated Services</h1>
        <h1>Guru Surgical</h1>
        <h1>Guru Surgicals</h1>
        <h1>Guru Group</h1>
        <h1>Guru Integrated Services</h1>
        <h1>Guru Surgical</h1>
      </div>
<div className="footer_container">

<div className="footer_content">
<h3>Quick Links</h3>
<ul>
  <li>  <a href='/'>Home</a> </li>
  <li>  <a href='/about'>About Us</a> </li> 
  <li>  <a href='/products'>Products</a> </li> 
  <li>  <a href='/contact'>Enquiry Us</a> </li>
</ul>
</div>


<div className="footer_content">
<h3>product categories</h3>
<ul>
  <li>  <a href='/products?param=SURGICAL_INSTRUMENTS'>Surgical Instruments</a> </li>
  <li>  <a href='/products?param=HOSPITAL_SCRUBS_LINENS'> Hospital Scrubs & Linens</a> </li> 
  <li>  <a href='/products?param=MEDICAL_DISPOSABLE'>Medical Disposable</a> </li> 
  <li>  <a href="/products?param=DIAGNOSTIC_EQUIPMENTS">Diagnostic Equipments</a> </li>
  <li>  <a href="/products?param=MEDICAL_RUBBER_PRODUCTS">Medical Rubber Products</a> </li>
</ul>
</div>


<div className="footer_content">
<h3>contact info</h3>
<p className='address'> 15/3-2 ESI Hospital double road, <br></br>
Indira Nagar, Bangalore - 560 038 . </p>
<p>1800 123 2434</p>
</div>

</div>
<p className="copyrights">
© 2023 Guru Surgicals 
</p>

    </div>
  )
}
