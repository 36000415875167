import React, { useEffect, useRef, useState } from 'react'
import './Header.css'
import { Helmet } from 'react-helmet';



export default function Header() {


  
  return (
    <>
     <Helmet>
        <title>Guru Surgicals | Manufacture And Supply Surgical Products</title>
        <meta name="description" content="Guru Surgicals is a trusted provider of high-quality surgical instruments and medical equipment. We offer a wide range of surgical instruments for various medical procedures, including general surgery, orthopedics, gynecology, and more. Our products are designed to meet the needs of healthcare professionals, providing precision, durability, and reliability. Visit us for all your surgical instrument needs." />
        <meta name="keywords" content="surgical instruments, medical equipment, high-quality surgical instruments, surgical instruments supplier, surgical equipment, general surgery instruments, orthopedic instruments, gynecology instruments, healthcare instruments, medical instrument provider" />
        <meta name="author" content="Rithish Abinav T" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        
        {/* Open Graph meta tags (for social media sharing) */}
        <meta property="og:title" content="Guru Surgicals | Manufacture And Supply Surgical Products" />
        <meta property="og:description" content="Guru Surgicals is a trusted provider of high-quality surgical instruments and medical equipment. We offer a wide range of surgical instruments for various medical procedures, including general surgery, orthopedics, gynecology, and more. Our products are designed to meet the needs of healthcare professionals, providing precision, durability, and reliability. Visit us for all your surgical instrument needs." />
        <meta property="og:image" content='http://gurusurgicals.com/static/media/Logo.9c55885c8bc49c394674.jpeg' />
        <meta property="og:url" content='http://gurusurgicals.com/' />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card meta tags (for Twitter sharing) */}
        <meta name="twitter:title" content="Guru Surgicals | Manufacture And Supply Surgical Products" />
        <meta name="twitter:description" content="Guru Surgicals is a trusted provider of high-quality surgical instruments and medical equipment. We offer a wide range of surgical instruments for various medical procedures, including general surgery, orthopedics, gynecology, and more. Our products are designed to meet the needs of healthcare professionals, providing precision, durability, and reliability. Visit us for all your surgical instrument needs." />
        <meta name="twitter:image" content='http://gurusurgicals.com/static/media/Logo.9c55885c8bc49c394674.jpeg' />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="for_seo">
        <h1>Guru Surgicals</h1>
        <h1>Guru Group</h1>
        <h1>Guru Integrated Services</h1>
        <h1>Guru Surgical</h1>
        <h1>Guru Surgicals</h1>
        <h1>Guru Group</h1>
        <h1>Guru Integrated Services</h1>
        <h1>Guru Surgical</h1>
      </div>
      <nav className="navbar navbar-expand-lg ">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
              <img loading="lazy"src={require('../Assets/Logo.jpeg')} alt="Logo" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/about">About Us</a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/products" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Products
                </a>
                <ul className="dropdown-menu">
                  <li><a className="dropdown-item" href="/products?param=DIAGNOSTIC_EQUIPMENTS">DIAGNOSTIC EQUIPMENTS</a></li>
                  <li><a className="dropdown-item" href="/products?param=HOSPITAL_SCRUBS_LINENS">HOSPITAL SCRUBS & LINENS</a></li>
                  <li><a className="dropdown-item" href="/products?param=SURGICAL_INSTRUMENTS">SURGICAL INSTRUMENTS</a></li>
                  <li><a className="dropdown-item" href="/products?param=MEDICAL_RUBBER_PRODUCTS">MEDICAL RUBBER PRODUCTS</a></li>
                  <li><a className="dropdown-item" href="/products?param=MEDICAL_DISPOSABLE">MEDICAL DISPOSABLE</a></li>
                  <li> <a className="dropdown-item" href="/products?param=PHYSIOTHERAPY_PRODUCTS" >PHYSIOTHERAPY PRODUCTS</a> </li>   
                                 <li><a className="dropdown-item view_all" href="/products">View All Products</a></li>
                </ul>
              </li>
               <li>           <a href='/contact'><button className="btn" id='contactBtn' type="submit">Enquiry Us</button></a> 
</li>
            </ul>
           {/* <a href='/contact'><button className="btn" id='contactBtn' type="submit">Enquiry Us</button></a>  */}
          </div>
        </div>
      </nav>


    </>
  )
}
