const LABORATORY_EQUIPMENTS=[
{
    ProductName: "Microscopes",
    ProductImage: require('../Assets/Products/LABORATORY EQUIPMENTS/microscopes.jpg')
},
{
    ProductName: "Centrifuge",
    ProductImage: require('../Assets/Products/LABORATORY EQUIPMENTS/centrifuge.jpg')
},{
    ProductName: "Water Bath",
    ProductImage: require('../Assets/Products/LABORATORY EQUIPMENTS/waterbath.jpg')
},{
    ProductName: "Hot Plates",
    ProductImage: require('../Assets/Products/LABORATORY EQUIPMENTS/hotplates.jpg')
},{
    ProductName: "Hot Air Sterilizers or Hot Air Ovens",
    ProductImage: require('../Assets/Products/LABORATORY EQUIPMENTS/Hot-Air-Sterilizer-or-Hot-Air-Oven.jpg')
},{
    ProductName: "Laboratory Incubators",
    ProductImage: require('../Assets/Products/LABORATORY EQUIPMENTS/Laboratory-Incubators.jpg')
},{
    ProductName: "Blood Cell Counter",
    ProductImage: require('../Assets/Products/LABORATORY EQUIPMENTS/blood-cell-counter.jpg')
},
]

export default LABORATORY_EQUIPMENTS;