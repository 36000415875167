const OT_EQUIPMENT=[
    {
        ProductName: "OT Lights",
        ProductImage: require('../Assets/Products/OT EQUIPMENT/ot-lights.jpg')
    },
    {
        ProductName: "OT Tables",
        ProductImage: require('../Assets/Products/OT EQUIPMENT/ot-tables.jpg')
    },{
        ProductName: "Examination Lights",
        ProductImage: require('../Assets/Products/OT EQUIPMENT/examination-lights.jpg')
    },{
        ProductName: "Electrosurgical Unit",
        ProductImage: require('../Assets/Products/OT EQUIPMENT/electrosurgical-unit.jpg')
    },
]

export default OT_EQUIPMENT;