const PHYSIOTHERAPY_PRODUCTS=[
{
    ProductName: "Slimming Equipments",
    ProductImage: require('../Assets/Products/PHYSIOTHERAPY PRODUCTS/slimming-equipments.jpg')
},
{
    ProductName: "Surgical Equipments",
    ProductImage: require('../Assets/Products/PHYSIOTHERAPY PRODUCTS/surgical-equipments.jpg')
},
]

export default PHYSIOTHERAPY_PRODUCTS;