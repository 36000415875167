const MEDICAL_DISPOSABLE=[
    {
        ProductName: "Anesthesia",
        ProductImage: require('../Assets/Products/MEDICAL DISPOSABLE/anesthesia.jpg')
    },
    {
        ProductName: "Transfusion",
        ProductImage: require('../Assets/Products/MEDICAL DISPOSABLE/transfusion.jpg')
    }, {
        ProductName: "Urology Care",
        ProductImage: require('../Assets/Products/MEDICAL DISPOSABLE/urology-products.jpg')
    }, {
        ProductName: "Gastro Care",
        ProductImage: require('../Assets/Products/MEDICAL DISPOSABLE/gastro-care.jpg')
    }, {
        ProductName: "Miscellaneous",
        ProductImage: require('../Assets/Products/MEDICAL DISPOSABLE/miscellaneous.jpg')
    }, {
        ProductName: "Cardiac Care",
        ProductImage: require('../Assets/Products/MEDICAL DISPOSABLE/cardiac-care.jpg')
    }, {
        ProductName: "Surgery",
        ProductImage: require('../Assets/Products/MEDICAL DISPOSABLE/surgery.jpg')
    }, {
        ProductName: "Patient Care",
        ProductImage: require('../Assets/Products/MEDICAL DISPOSABLE/patient-care.jpg')
    },
]

export default MEDICAL_DISPOSABLE;