const ORTHOPEDIC_INSTRUMENTS=[

{
    ProductName: "Bone Cutting Forceps",
    ProductImage: require('../Assets/Products/ORTHOPEDIC INSTRUMENTS/bone-cutting-forceps.jpg')
},
{
    ProductName: "Bone Drill and Accessories",
    ProductImage: require('../Assets/Products/ORTHOPEDIC INSTRUMENTS/bone-drill-and-accessories.jpg')
},{
    ProductName: "Bone Hammer",
    ProductImage: require('../Assets/Products/ORTHOPEDIC INSTRUMENTS/bone-hammer.jpg')
},{
    ProductName: "Bone Holding Forceps & Clamp",
    ProductImage: require('../Assets/Products/ORTHOPEDIC INSTRUMENTS/bone-holding-forceps-clamp.jpg')
},{
    ProductName: "Bone Levers",
    ProductImage: require('../Assets/Products/ORTHOPEDIC INSTRUMENTS/bone-levers.jpg')
},{
    ProductName: "Chisel",
    ProductImage: require('../Assets/Products/ORTHOPEDIC INSTRUMENTS/orthopedic-chisel.jpg')
},{
    ProductName: "Osteotome",
    ProductImage: require('../Assets/Products/ORTHOPEDIC INSTRUMENTS/osteotome.jpg')
},{
    ProductName: "Periosteal Elevator",
    ProductImage: require('../Assets/Products/ORTHOPEDIC INSTRUMENTS/orthopedic-periosteal-elevator.jpg')
},{
    ProductName: "Plate Bender",
    ProductImage: require('../Assets/Products/ORTHOPEDIC INSTRUMENTS/orthopedic-plate-bender.jpg')
},{
    ProductName: "Screw Driver",
    ProductImage: require('../Assets/Products/ORTHOPEDIC INSTRUMENTS/orthopedic-screw-driver.jpg')
},{
    ProductName: "Sterilization Tubes",
    ProductImage: require('../Assets/Products/ORTHOPEDIC INSTRUMENTS/sterilization-tubes.jpg')
},{
    ProductName: "Bone Nibbler",
    ProductImage: require('../Assets/Products/ORTHOPEDIC INSTRUMENTS/bone-nibbler.jpg')
},{
    ProductName: "Other Orthopedic Instruments",
    ProductImage: require('../Assets/Products/ORTHOPEDIC INSTRUMENTS/other-orthopedic-instruments.jpg')
},{
    ProductName: "Reamer",
    ProductImage: require('../Assets/Products/ORTHOPEDIC INSTRUMENTS/orthopedic-reamers.jpg')
},{
    ProductName: "Instruments Sets",
    ProductImage: require('../Assets/Products/ORTHOPEDIC INSTRUMENTS/instruments-sets.jpg')
},{
    ProductName: "Power Tools",
    ProductImage: require('../Assets/Products/ORTHOPEDIC INSTRUMENTS/power-tools.jpg')
},

]

export default ORTHOPEDIC_INSTRUMENTS;