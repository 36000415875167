const ProductCategories = [
    {
        image: require('./Assets/Products/HOSPITAL DISPOSABLE PRODUCTS/hospital-uniforms-dresses.jpg'),
        title: "HOSPITAL DISPOSABLE PRODUCTS",
    },
    {
        image: require('./Assets/Products/HOSPITAL SCRUBS & LINENS/surgeon-patient-gown-manufacturer.jpg'),
        title: "HOSPITAL SCRUBS & LINENS",
    },
    {
        image: require('./Assets/Products/ANAESTHESIA EQUIPMENTS/anaesthesia-face-masks.jpg'),
        title: "ANAESTHESIA EQUIPMENTS",
    },
    {
        image: require('./Assets/Products/ORTHOPAEDIC AIDS & REHAB PRODUCTS/back-splints.jpg'),
        title: "ORTHOPAEDIC AIDS & REHAB PRODUCTS",
    },
    {
        image: require('./Assets/Products/SURGICAL INSTRUMENTS/cardiothoracic-instruments.jpg'),
        title: "SURGICAL INSTRUMENTS",
    },
    {
        image: require('./Assets/Products/HOSPITAL FURNITURE/wheel-chair.jpg'),
        title: "HOSPITAL FURNITURE",
    },
    {
        image: require('./Assets/Products/AUTOCLAVE & STERILIZER/vertical-horizontal-autoclaves.jpg'),
        title: "AUTOCLAVE & STERILIZER",
    }, {
        image: require('./Assets/Products/COLD CHAIN EQUIPMENT/cold-box.jpg'),
        title: "COLD CHAIN EQUIPMENT",
    }, {
        image: require('./Assets/Products/DENTAL INSTRUMENTS/dental-instrument-kits.jpg'),
        title: "DENTAL INSTRUMENTS",
    }, {
        image: require('./Assets/Products/DIAGNOSTIC EQUIPMENTS/sphygmomanometers.jpg'),
        title: "DIAGNOSTIC EQUIPMENTS",
    }, {
        image: require('./Assets/Products/HEALTH & YOGA PRODUCTS/enema-products.jpg'),
        title: "HEALTH & YOGA PRODUCTS",
    }, {
        image: require('./Assets/Products/HEIGHT & WEIGHT SCALES/personal-weighing-scale.jpg'),
        title: "HEIGHT & WEIGHT SCALES",
    }, {
        image: require('./Assets/Products/HOSPITAL HOLLOWARE/catheter-instrument-tray.jpg'),
        title: "HOSPITAL HOLLOWARE",
    }, {
        image: require('./Assets/Products/INFANT CARE EQUIPMENT/phototherapy-unit.jpg'),
        title: "INFANT CARE EQUIPMENT",
    }, {
        image: require('./Assets/Products/LABORATORY EQUIPMENTS/microscopes.jpg'),
        title: "LABORATORY EQUIPMENTS",
    }, {
        image: require('./Assets/Products/LABORATORY PLASTICWARE/plastic-measuring-jugs.jpg'),
        title: "LABORATORY PLASTICWARE",
    }, {
        image: require('./Assets/Products/MEDICAL DISPOSABLE/urology-products.jpg'),
        title: "MEDICAL DISPOSABLE",
    }, {
        image: require('./Assets/Products/MEDICAL RUBBER PRODUCTS/mackintosh-sheet.jpg'),
        title: "MEDICAL RUBBER PRODUCTS",
    }, {
        image: require('./Assets/Products/MISCELLANEOUS MEDICAL PRODUCTS/oxygen-concentrator.jpg'),
        title: "MISCELLANEOUS MEDICAL PRODUCTS",
    }, {
        image: require('./Assets/Products/OBSTETRICS & GYNAECOLOGY/vacuum-extractor-manual.jpg'),
        title: "OBSTETRICS & GYNAECOLOGY",
    }, {
        image: require('./Assets/Products/ORTHOPEDIC INSTRUMENTS/instruments-sets.jpg'),
        title: "ORTHOPEDIC INSTRUMENTS",
    }, {
        image: require('./Assets/Products/OT EQUIPMENT/electrosurgical-unit.jpg'),
        title: "OT EQUIPMENT",
    }, {
        image: require('./Assets/Products/PHYSIOTHERAPY PRODUCTS/slimming-equipments.jpg'),
        title: "PHYSIOTHERAPY PRODUCTS",
    }, {
        image: require('./Assets/Products/SUCTION MACHINES & UNITS/portable-suction-units.jpg'),
        title: "SUCTION MACHINES & UNITS",
    },
    // {
    //     image:require('./Assets/Products/X-RAY SUPPLIES/x-ray-view-box.jpg'),
    //     title:"X-RAY SUPPLIES",
    // }


]

export default ProductCategories;