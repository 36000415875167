const SUCTION_MACHINES_UNITS=[
    {
        ProductName: "Suction Units - Electric",
        ProductImage: require('../Assets/Products/SUCTION MACHINES & UNITS/suction-units-electric.jpg')
    },
    {
        ProductName: "Portable Suction Machine",
        ProductImage: require('../Assets/Products/SUCTION MACHINES & UNITS/portable-suction-units.jpg')
    }, {
        ProductName: "Electric Cum Manual Suction Units",
        ProductImage: require('../Assets/Products/SUCTION MACHINES & UNITS/suction-units-electric-cum-manual.jpg')
    }, {
        ProductName: "Suction Units Manual",
        ProductImage: require('../Assets/Products/SUCTION MACHINES & UNITS/suction-units-manual.jpg')
    },
]

export default SUCTION_MACHINES_UNITS;