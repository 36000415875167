import React, { useRef, useState } from 'react'
import './Contact.css'
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet';
import logo from "../Assets/Logo.jpg"

export default function Contact() {
  const form = useRef();

  const[name,setName]=useState('');
  const[email,setEmail]=useState('');
  const[phone,setPhone]=useState('');
  const[message,setMessage]=useState("")
  const[err,setErr]=useState("")


  const sendEmail = (e) => {

    e.preventDefault();
    if(name=="" || email=="" || phone=="" || message==""){
setErr("Please Fill All the Fields")
    }
    else{
      setErr("")
  const btn = document.getElementById('btn');
  const originalButtonText = btn.value;

  emailjs.sendForm('service_pxrkyxj', 'template_yto2ihn', form.current, 'DIa2gSgKlZOdU18RA')
    .then((result) => {
      console.log(result.text);
      btn.value = 'Sending';
      console.log('sent');

      setTimeout(() => {
        btn.value ='Message Sent Successfully';
      }, 2000); // Revert button text after 2 seconds
    })
    .catch((error) => {
      console.log(error.text);
    });
  }

};


 
  return (
    <div className='contact_page'>
 <Helmet>
        <title>Guru Surgicals | Manufacture And Supply Surgical Products</title>
        <meta name="description" content="Guru Surgicals is a trusted provider of high-quality surgical instruments and medical equipment. We offer a wide range of surgical instruments for various medical procedures, including general surgery, orthopedics, gynecology, and more. Our products are designed to meet the needs of healthcare professionals, providing precision, durability, and reliability. Visit us for all your surgical instrument needs." />
        <meta name="keywords" content="surgical instruments, medical equipment, high-quality surgical instruments, surgical instruments supplier, surgical equipment, general surgery instruments, orthopedic instruments, gynecology instruments, healthcare instruments, medical instrument provider" />
        <meta name="author" content="Rithish Abinav T" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        
        {/* Open Graph meta tags (for social media sharing) */}
        <meta property="og:title" content="Guru Surgicals | Manufacture And Supply Surgical Products" />
        <meta property="og:description" content="Guru Surgicals is a trusted provider of high-quality surgical instruments and medical equipment. We offer a wide range of surgical instruments for various medical procedures, including general surgery, orthopedics, gynecology, and more. Our products are designed to meet the needs of healthcare professionals, providing precision, durability, and reliability. Visit us for all your surgical instrument needs." />
        <meta property="og:image" content='http://gurusurgicals.com/static/media/Logo.9c55885c8bc49c394674.jpeg' />
        <meta property="og:url" content='http://gurusurgicals.com/' />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card meta tags (for Twitter sharing) */}
        <meta name="twitter:title" content="Guru Surgicals | Manufacture And Supply Surgical Products" />
        <meta name="twitter:description" content="Guru Surgicals is a trusted provider of high-quality surgical instruments and medical equipment. We offer a wide range of surgical instruments for various medical procedures, including general surgery, orthopedics, gynecology, and more. Our products are designed to meet the needs of healthcare professionals, providing precision, durability, and reliability. Visit us for all your surgical instrument needs." />
        <meta name="twitter:image" content='http://gurusurgicals.com/static/media/Logo.9c55885c8bc49c394674.jpeg' />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="for_seo">
        <h1>Guru Surgicals</h1>
        <h1>Guru Group</h1>
        <h1>Guru Integrated Services</h1>
        <h1>Guru Surgical</h1>
        <h1>Guru Surgicals</h1>
        <h1>Guru Group</h1>
        <h1>Guru Integrated Services</h1>
        <h1>Guru Surgical</h1>
      </div>

      <div className="banner">
        <h2>Contact Us</h2>

      </div>

      <div className="contact_badges">

        <div className="_badge">
          <i class="fa-solid fa-map-location-dot"></i>
          <h4>Address</h4>
          <p>15/3-2, ESI Hospital, Double Road, Indira Nagar, Bangalore 560038</p>
        </div>


        <div className="_badge">
          <i class="fa-solid fa-phone"></i>
          <h4>Phone</h4>
          <p>1800 123 2434<br></br><br></br>


          </p>
        </div>

        <div className="_badge">
          <i class="fa-solid fa-envelope"></i>
          <h4>Email</h4>
          <p>caregurusurgical@gmail.com<br></br><br></br></p>
        </div>

      </div>

      <div className="contact_container">
        <div className="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15551.88924958256!2d77.6359403!3d12.9736227!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17893b6287d7%3A0xdaf1fa5f40f6c720!2sGuru%20Surgicals!5e0!3m2!1sen!2sin!4v1687409681058!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>        </div>

        <div className="contact_form">
          <form ref={form} onSubmit={sendEmail}>
            <h4>Let’s Get In Touch</h4>
            <input type="text" value={name}  onChange={(e)=>setName(e.target.value)} name="user_name" placeholder='Name' />
            <input type="email" value={email}  onChange={(e)=>setEmail(e.target.value)} name="user_email" placeholder='Email' />
            <input type='text' value={phone} onChange={(e)=>setPhone(e.target.value)} maxLength={10} placeholder='Phone No' name='phonenumber' />
            <input type="text" placeholder='Subject' name='subject' />
            <textarea name="message" value={message} onChange={(e)=>setMessage(e.target.value)} placeholder='Type here...' />
            <p className='error'>{err}</p>
            <input id='btn' type="submit" value="Send" />
          </form>
        </div>

      </div>


    </div>
  )
}
