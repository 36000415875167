const HOSPITAL_HOLLOWARE=[
    {
        ProductName: "Kidney Tray",
        ProductImage: require('../Assets/Products/HOSPITAL HOLLOWARE/kidney-tray.jpg')
    },
    {
        ProductName: "Instrument Tray",
        ProductImage: require('../Assets/Products/HOSPITAL HOLLOWARE/instrument-tray.jpg')
    }, {
        ProductName: "Catheter Instrument Tray",
        ProductImage: require('../Assets/Products/HOSPITAL HOLLOWARE/catheter-instrument-tray.jpg')
    }, {
        ProductName: "Bed Pan and Urinal",
        ProductImage: require('../Assets/Products/HOSPITAL HOLLOWARE/bed-pan-urinal.jpg')
    }, {
        ProductName: "Douche Can",
        ProductImage: require('../Assets/Products/HOSPITAL HOLLOWARE/douche-can.jpg')
    }, {
        ProductName: "Bowl & Basin",
        ProductImage: require('../Assets/Products/HOSPITAL HOLLOWARE/bowl-basin.jpg')
    }, {
        ProductName: "Dressing Drum",
        ProductImage: require('../Assets/Products/HOSPITAL HOLLOWARE/dressing-drum.jpg')
    }, {
        ProductName: "Thermometer & Forceps Jar",
        ProductImage: require('../Assets/Products/HOSPITAL HOLLOWARE/thermometer-and-forceps-jars.jpg')
    }, {
        ProductName: "Feeding Cup",
        ProductImage: require('../Assets/Products/HOSPITAL HOLLOWARE/feeding-cup.jpg')
    }, {
        ProductName: "Gallipot",
        ProductImage: require('../Assets/Products/HOSPITAL HOLLOWARE/gallipot.jpg')
    }, {
        ProductName: "Sputum Mug",
        ProductImage: require('../Assets/Products/HOSPITAL HOLLOWARE/sputum-mug.jpg')
    }, {
        ProductName: "Dressing Jar",
        ProductImage: require('../Assets/Products/HOSPITAL HOLLOWARE/dressing-drum.jpg')
    }, {
        ProductName: "Bucket",
        ProductImage: require('../Assets/Products/HOSPITAL HOLLOWARE/bucket.jpg')
    }, {
        ProductName: "Waste Bin",
        ProductImage: require('../Assets/Products/HOSPITAL HOLLOWARE/waste-bin.jpg')
    }, {
        ProductName: "Cidex Tray",
        ProductImage: require('../Assets/Products/HOSPITAL HOLLOWARE/cidex-tray.jpg')
    },
]
export default HOSPITAL_HOLLOWARE;