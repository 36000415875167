const DENTAL_INSTRUMENTS = [
    {
        ProductName: "Burnishers",
        ProductImage: require('../Assets/Products/DENTAL INSTRUMENTS/burnishers.jpg')
    },
    {
        ProductName: "Carvers",
        ProductImage: require('../Assets/Products/DENTAL INSTRUMENTS/dental-carvers.jpg')
    }, {
        ProductName: "Cement Spatulas",
        ProductImage: require('../Assets/Products/DENTAL INSTRUMENTS/cement-spatulas.jpg')
    }, {
        ProductName: "Chisels",
        ProductImage: require('../Assets/Products/DENTAL INSTRUMENTS/chisels.jpg')
    }, {
        ProductName: "College Pliers",
        ProductImage: require('../Assets/Products/DENTAL INSTRUMENTS/college-pliers.jpg')
    }, {
        ProductName: "Cord Packers",
        ProductImage: require('../Assets/Products/DENTAL INSTRUMENTS/cord-packers.jpg')
    }, {
        ProductName: "Curettes",
        ProductImage: require('../Assets/Products/DENTAL INSTRUMENTS/curettes.jpg')
    }, {
        ProductName: "Dental Instrument Kits",
        ProductImage: require('../Assets/Products/DENTAL INSTRUMENTS/dental-instrument-kits.jpg')
    }, {
        ProductName: "Dissectors",
        ProductImage: require('../Assets/Products/DENTAL INSTRUMENTS/dissectors.jpg')
    }, {
        ProductName: "Elevators",
        ProductImage: require('../Assets/Products/DENTAL INSTRUMENTS/elevators.jpg')
    }, {
        ProductName: "Extraction Forceps",
        ProductImage: require('../Assets/Products/DENTAL INSTRUMENTS/extraction-forceps.jpg')
    }, {
        ProductName: "Orthodontics Instruments",
        ProductImage: require('../Assets/Products/DENTAL INSTRUMENTS/orthodontics.jpg')
    }, {
        ProductName: "Wax Placers",
        ProductImage: require('../Assets/Products/DENTAL INSTRUMENTS/wax-placers.jpg')
    }, {
        ProductName: "General Dentistry",
        ProductImage: require('../Assets/Products/DENTAL INSTRUMENTS/general-dentistry.jpg')
    }, {
        ProductName: "Dental Caliper",
        ProductImage: require('../Assets/Products/DENTAL INSTRUMENTS/dental-caliper.jpg')
    },
]

export default DENTAL_INSTRUMENTS;