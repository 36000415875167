const OBSTETRICS_GYNAECOLOGY=[
    {
        ProductName: "Vacuum Extractor Sets - Manual",
        ProductImage: require('../Assets/Products/OBSTETRICS & GYNAECOLOGY/vacuum-extractor-manual.jpg')
    },
    {
        ProductName: "Vacuum Extractor Sets - Electric",
        ProductImage: require('../Assets/Products/OBSTETRICS & GYNAECOLOGY/vacuum-extractor-electric.jpg')
    }, {
        ProductName: "Vaginal Speculum",
        ProductImage: require('../Assets/Products/OBSTETRICS & GYNAECOLOGY/vaginal-speculums-cusco-duck-bill.jpg')
    }, {
        ProductName: "Gynecological Aspiration Kit (MVA Kit)",
        ProductImage: require('../Assets/Products/OBSTETRICS & GYNAECOLOGY/gynecological-aspiration-kit.jpg')
    }, {
        ProductName: "Pessaries",
        ProductImage: require('../Assets/Products/OBSTETRICS & GYNAECOLOGY/pessaries.jpg')
    }, {
        ProductName: "Latex Gynecological Gloves",
        ProductImage: require('../Assets/Products/OBSTETRICS & GYNAECOLOGY/latex-gynecological-gloves.jpg')
    }, {
        ProductName: "Umbilical Cord Clamp",
        ProductImage: require('../Assets/Products/OBSTETRICS & GYNAECOLOGY/umbilical-cord-clamp.jpg')
    }, {
        ProductName: "Midwifery Case",
        ProductImage: require('../Assets/Products/OBSTETRICS & GYNAECOLOGY/midwifery-case.jpg')
    }, {
        ProductName: "Breast Pumps",
        ProductImage: require('../Assets/Products/OBSTETRICS & GYNAECOLOGY/breast-pumps.jpg')
    },
]

export default OBSTETRICS_GYNAECOLOGY;