const INFANT_CARE_EQUIPMENT=[
    {
        ProductName: "Infant Radiant Warmer",
        ProductImage: require('../Assets/Products/INFANT CARE EQUIPMENT/infant-radiant-warmer.jpg')
    },
    {
        ProductName: "Infant Incubators",
        ProductImage: require('../Assets/Products/INFANT CARE EQUIPMENT/infant-incubators.jpg')
    }, {
        ProductName: "Resuscitation Unit",
        ProductImage: require('../Assets/Products/INFANT CARE EQUIPMENT/resuscitation-unit.jpg')
    }, {
        ProductName: "Oxygen Hoods & Accessories",
        ProductImage: require('../Assets/Products/INFANT CARE EQUIPMENT/oxygen-hoods.jpg')
    }, {
        ProductName: "Phototherapy Unit",
        ProductImage: require('../Assets/Products/INFANT CARE EQUIPMENT/phototherapy-unit.jpg')
    },
]

export default INFANT_CARE_EQUIPMENT;