import React from 'react'
import './About.css'
import { Helmet } from 'react-helmet';
import logo from "../Assets/Logo.jpg"

export default function About() {
  return (
    <div className='about_page'>
   <Helmet>
        <title>Guru Surgicals | Manufacture And Supply Surgical Products</title>
        <meta name="description" content="Guru Surgicals is a trusted provider of high-quality surgical instruments and medical equipment. We offer a wide range of surgical instruments for various medical procedures, including general surgery, orthopedics, gynecology, and more. Our products are designed to meet the needs of healthcare professionals, providing precision, durability, and reliability. Visit us for all your surgical instrument needs." />
        <meta name="keywords" content="surgical instruments, medical equipment, high-quality surgical instruments, surgical instruments supplier, surgical equipment, general surgery instruments, orthopedic instruments, gynecology instruments, healthcare instruments, medical instrument provider" />
        <meta name="author" content="Rithish Abinav T" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        
        {/* Open Graph meta tags (for social media sharing) */}
        <meta property="og:title" content="Guru Surgicals | Manufacture And Supply Surgical Products" />
        <meta property="og:description" content="Guru Surgicals is a trusted provider of high-quality surgical instruments and medical equipment. We offer a wide range of surgical instruments for various medical procedures, including general surgery, orthopedics, gynecology, and more. Our products are designed to meet the needs of healthcare professionals, providing precision, durability, and reliability. Visit us for all your surgical instrument needs." />
        <meta property="og:image" content='http://gurusurgicals.com/static/media/Logo.9c55885c8bc49c394674.jpeg' />
        <meta property="og:url" content='http://gurusurgicals.com/' />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card meta tags (for Twitter sharing) */}
        <meta name="twitter:title" content="Guru Surgicals | Manufacture And Supply Surgical Products" />
        <meta name="twitter:description" content="Guru Surgicals is a trusted provider of high-quality surgical instruments and medical equipment. We offer a wide range of surgical instruments for various medical procedures, including general surgery, orthopedics, gynecology, and more. Our products are designed to meet the needs of healthcare professionals, providing precision, durability, and reliability. Visit us for all your surgical instrument needs." />
        <meta name="twitter:image" content='http://gurusurgicals.com/static/media/Logo.9c55885c8bc49c394674.jpeg' />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="for_seo">
        <h1>Guru Surgicals</h1>
        <h1>Guru Group</h1>
        <h1>Guru Integrated Services</h1>
        <h1>Guru Surgical</h1>
        <h1>Guru Surgicals</h1>
        <h1>Guru Group</h1>
        <h1>Guru Integrated Services</h1>
        <h1>Guru Surgical</h1>
      </div>
<div className="banner">
        <h2>About Us</h2>
      
      </div>

      <main>
          <div className="main_content">
            <p>At Guru Surgicals, we are committed to providing top-quality surgical products and equipment to meet the diverse needs of healthcare professionals. With years of industry experience, we have established ourselves as a trusted name in the medical field.
              <br></br><br></br>
              Our mission is to enhance patient care and improve medical outcomes by delivering reliable, innovative, and cutting-edge surgical solutions. We strive to stay at the forefront of advancements in medical technology, constantly expanding our product range to ensure we offer the latest and most effective tools to our customers.
            </p>

<div className="logo_image">
    <img loading="lazy"src={require('../Assets/Logo.jpg')} width='100%' />
</div>

          </div>



<p className='about_us_content'>

What sets us apart is our unwavering dedication to excellence. We work closely with renowned manufacturers and suppliers to source products that meet the highest standards of quality, precision, and safety. Every item in our inventory undergoes rigorous testing and quality control processes, ensuring that our customers receive only the best.
<br></br> <br></br>
At Guru Surgicals, we understand the importance of personalized service. Our knowledgeable and experienced team is always ready to assist you in finding the right surgical products that suit your specific requirements. We believe in building strong relationships with our customers, based on trust, integrity, and reliability.
We take pride in our prompt and efficient customer support. From order processing to after-sales assistance, we strive to provide a seamless experience for our valued customers. Your satisfaction is our priority, and we are committed to exceeding your expectations.
<br></br><br></br>
Whether you are a hospital, clinic, or individual healthcare professional, Guru Surgicals is your one-stop destination for all your surgical needs. We offer a comprehensive range of instruments, equipment, and supplies across various specialties, ensuring that you have access to everything you need under one roof.
<br></br><br></br>
Contact us today and experience the Guru Surgicals difference.
</p>

<div className="qa">
  <h3>What sets us apart as your trusted surgical product supplier?</h3>
  <p> <span> Uncompromising Quality:</span> We understand that patient well-being hinges on the quality of surgical products. That's why every item in our inventory undergoes rigorous testing and adheres to the strictest industry standards.</p>
<p> <span>Extensive Product Portfolio:</span>  Our vast selection of instruments, equipment, and supplies caters to a wide range of medical specialties. From cutting-edge surgical tools to advanced implants, we offer comprehensive solutions to meet your specific needs.</p>
<p> <span>Expert Consultation:</span>  Our team of seasoned professionals possesses deep industry knowledge and expertise. We stand ready to provide personalized guidance and support, assisting you in making informed decisions that optimize patient outcomes.</p>
<p> <span>Efficient Supply Chain:</span>  Time is of the essence in healthcare. We pride ourselves on a streamlined logistics process that ensures prompt delivery of your orders. Count on us to reliably meet your supply demands, allowing you to focus on providing superior care.</p>
<p> <span>Dedicated Customer Service:</span>  Your satisfaction and peace of mind are paramount to us. Our responsive customer support team is always available to address any inquiries or concerns you may have, ensuring a seamless experience throughout our partnership.</p>

</div>

<div className="brochure">
  <a download href={require('../Assets/Brochure/Guru Surgical Brochure.pdf')}><button><i class="fa-solid fa-download"></i> Our Brochure</button> </a>
</div>





        </main>

    </div>
  )
}
