const DIAGNOSTIC_EQUIPMENTS = [
    {
        ProductName: "Stethoscopes",
        ProductImage: require('../Assets/Products/DIAGNOSTIC EQUIPMENTS/stethoscopes.jpg')
    },
    {
        ProductName: "BP Monitors & Sphygmomanometers",
        ProductImage: require('../Assets/Products/DIAGNOSTIC EQUIPMENTS/sphygmomanometers.jpg')
    }, {
        ProductName: "Laryngoscopes",
        ProductImage: require('../Assets/Products/DIAGNOSTIC EQUIPMENTS/laryngoscopes.jpg')
    }, {
        ProductName: "Mediacal Hammer",
        ProductImage: require('../Assets/Products/DIAGNOSTIC EQUIPMENTS/medical-hammers.jpg')
    }, {
        ProductName: "Ecg Machine",
        ProductImage: require('../Assets/Products/DIAGNOSTIC EQUIPMENTS/ecg-machine.jpg')
    }, {
        ProductName: "Fetal Doppler & Monitor",
        ProductImage: require('../Assets/Products/DIAGNOSTIC EQUIPMENTS/fetal-doppler-monitor.jpg')
    }, {
        ProductName: "Patient Monitor",
        ProductImage: require('../Assets/Products/DIAGNOSTIC EQUIPMENTS/patient-monitor.jpg')
    }, {
        ProductName: "Pulse Oximeter",
        ProductImage: require('../Assets/Products/DIAGNOSTIC EQUIPMENTS/pulse-oximeter.jpg')
    }, {
        ProductName: "Tongue Depressors",
        ProductImage: require('../Assets/Products/DIAGNOSTIC EQUIPMENTS/tongue-depressor.jpg')
    }, {
        ProductName: "Otoscopes",
        ProductImage: require('../Assets/Products/DIAGNOSTIC EQUIPMENTS/otoscopes.jpg')
    },
    {
        ProductName: "Ophthalmology Instruments",
        ProductImage: require('../Assets/Products/DIAGNOSTIC EQUIPMENTS/ophthalmology-instruments.jpg')
    },{
        ProductName: "Tuning Fork",
        ProductImage: require('../Assets/Products/DIAGNOSTIC EQUIPMENTS/tuning-fork.jpg')
    },{
        ProductName: "Goniometer",
        ProductImage: require('../Assets/Products/DIAGNOSTIC EQUIPMENTS/goniometer.jpg')
    },{
        ProductName: "Nebulizers",
        ProductImage: require('../Assets/Products/DIAGNOSTIC EQUIPMENTS/nebulizer.jpg')
    },{
        ProductName: "Thermometers",
        ProductImage: require('../Assets/Products/DIAGNOSTIC EQUIPMENTS/thermometers.jpg')
    },{
        ProductName: "Glucometers",
        ProductImage: require('../Assets/Products/DIAGNOSTIC EQUIPMENTS/glucometer.jpg')
    },
    {
        ProductName: "Peak Flow Meter",
        ProductImage: require('../Assets/Products/DIAGNOSTIC EQUIPMENTS/peak-flow-meter.jpg')
    },{
        ProductName: "Aero Spacer",
        ProductImage: require('../Assets/Products/DIAGNOSTIC EQUIPMENTS/aero-spacer.jpg')
    },

]

export default DIAGNOSTIC_EQUIPMENTS;