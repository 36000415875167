const MEDICAL_RUBBER_PRODUCTS=[
{
    ProductName: "Air Cushion (Invalid Air Rings)",
    ProductImage: require('../Assets/Products/MEDICAL RUBBER PRODUCTS/air-cushion-invalid-air-rings.jpg')
},
{
    ProductName: "Enema Syringes",
    ProductImage: require('../Assets/Products/MEDICAL RUBBER PRODUCTS/enema-syringes.jpg')
},{
    ProductName: "Rubber Drainage Sheet",
    ProductImage: require('../Assets/Products/MEDICAL RUBBER PRODUCTS/rubber-drainage-sheet-corrugated.jpg')
},{
    ProductName: "Chloroform Bellow",
    ProductImage: require('../Assets/Products/MEDICAL RUBBER PRODUCTS/chloroform-bellow.jpg')
},{
    ProductName: "Eye/Ear & Ulcer Syringe",
    ProductImage: require('../Assets/Products/MEDICAL RUBBER PRODUCTS/eye-ear-ulcer-syringe.jpg')
},{
    ProductName: "Infant Rectal Syringe",
    ProductImage: require('../Assets/Products/MEDICAL RUBBER PRODUCTS/infant-rectal-syringe.jpg')
},{
    ProductName: "Vaginal Douche Spray",
    ProductImage: require('../Assets/Products/MEDICAL RUBBER PRODUCTS/vaginal-douche-spray.jpg')
},{
    ProductName: "Stomach Pump Tube",
    ProductImage: require('../Assets/Products/MEDICAL RUBBER PRODUCTS/stomach-pump-tube.jpg')
},{
    ProductName: "Mackintosh Sheet",
    ProductImage: require('../Assets/Products/MEDICAL RUBBER PRODUCTS/mackintosh-sheet.jpg')
},{
    ProductName: "Douche Bag",
    ProductImage: require('../Assets/Products/MEDICAL RUBBER PRODUCTS/douche-bag.jpg')
},{
    ProductName: "Hot Water Bottles",
    ProductImage: require('../Assets/Products/MEDICAL RUBBER PRODUCTS/hot-water-bottles.jpg')
},{
    ProductName: "Tourniquet",
    ProductImage: require('../Assets/Products/MEDICAL RUBBER PRODUCTS/tourniquet.jpg')
},{
    ProductName: "Kelly's Pad",
    ProductImage: require('../Assets/Products/MEDICAL RUBBER PRODUCTS/kellys-pad.jpg')
},{
    ProductName: "Pessary Rubber Ring",
    ProductImage: require('../Assets/Products/MEDICAL RUBBER PRODUCTS/pessary-rubber-ring.jpg')
},{
    ProductName: "Nasal Aspirator",
    ProductImage: require('../Assets/Products/MEDICAL RUBBER PRODUCTS/nasal-aspirator.jpg')
},{
    ProductName: "Pipette bulb",
    ProductImage: require('../Assets/Products/MEDICAL RUBBER PRODUCTS/pipette-bulb.jpg')
},{
    ProductName: "Breast Pump",
    ProductImage: require('../Assets/Products/MEDICAL RUBBER PRODUCTS//breast-pump.jpg')
},
]

export default MEDICAL_RUBBER_PRODUCTS;