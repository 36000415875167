const MISCELLANEOUS_MEDICAL_PRODUCTS=[
{
    ProductName: "Fumigator & Fogger",
    ProductImage: require('../Assets/Products/MISCELLANEOUS MEDICAL PRODUCTS/aerosol-disinfector.jpg')
},
{
    ProductName: "Emergency Blankets",
    ProductImage: require('../Assets/Products/MISCELLANEOUS MEDICAL PRODUCTS/emergency-blankets.jpg')
},
{
    ProductName: "Needle and Syringe Destroyer",
    ProductImage: require('../Assets/Products/MISCELLANEOUS MEDICAL PRODUCTS/needle-and-syringe-destroyer.jpg')
},{
    ProductName: "Pneumatic Tourniquet",
    ProductImage: require('../Assets/Products/MISCELLANEOUS MEDICAL PRODUCTS/pneumatic-tourniquet.jpg')
},{
    ProductName: "Bedsore Mattress",
    ProductImage: require('../Assets/Products/MISCELLANEOUS MEDICAL PRODUCTS/bedsore-mattress.jpg')
},{
    ProductName: "pH Test Strips",
    ProductImage: require('../Assets/Products/MISCELLANEOUS MEDICAL PRODUCTS/ph-test-strips.jpg')
},{
    ProductName: "pH Buffer",
    ProductImage: require('../Assets/Products/MISCELLANEOUS MEDICAL PRODUCTS/ph-buffer.jpg')
},{
    ProductName: "Oxygen Concentrator",
    ProductImage: require('../Assets/Products/MISCELLANEOUS MEDICAL PRODUCTS/oxygen-concentrator.jpg')
},{
    ProductName: "Examination Lamp",
    ProductImage: require('../Assets/Products/MISCELLANEOUS MEDICAL PRODUCTS/examination-lamp.jpg')
},{
    ProductName: "Nail Brushes",
    ProductImage: require('../Assets/Products/MISCELLANEOUS MEDICAL PRODUCTS/nail-brushes.jpg')
},{
    ProductName: "Kerosene Stove, Hurricane Lantern & Petromax",
    ProductImage: require('../Assets/Products/MISCELLANEOUS MEDICAL PRODUCTS/kerosene-stove-hurricane-lantern-petromax.jpg')
},{
    ProductName: "Timer and Stop Watch",
    ProductImage: require('../Assets/Products/MISCELLANEOUS MEDICAL PRODUCTS/timer-and-stop-watch.jpg')
}
]
export default MISCELLANEOUS_MEDICAL_PRODUCTS;