import React, { useEffect, useRef, useState } from 'react';
import deskWhy from '../Assets/why.png';
import mobWhy from '../Assets/why_m.png'
import { Helmet } from 'react-helmet';
import logo from "../Assets/Logo.jpg"
import './Home.css';
import ProductCategories from '../ProductCategories';
import HOSPITAL_DISPOSABLE_PRODUCTS from '../ProductDetails/HOSPITAL DISPOSABLE PRODUCTS';
import HOSPITAL_SCRUBS_LINENS from '../ProductDetails/HOSPITAL SCRUBS & LINENS';


export default function Home() {

  const all=useRef(null)

  const Ourproduct = [...HOSPITAL_DISPOSABLE_PRODUCTS, HOSPITAL_SCRUBS_LINENS[2], HOSPITAL_SCRUBS_LINENS[3]];

  const viewProduct = (item) => {
console.log(item)
if(item.title=='ANAESTHESIA EQUIPMENTS'){
  window.location.href = '/products?param=ANAESTHESIA EQUIPMENTS';
}
else if(item.title=='AUTOCLAVE & STERILIZER'){
  window.location.href = '/products?param=AUTOCLAVE_STERILIZER';
}
else if(item.title=='COLD CHAIN EQUIPMENT'){
  window.location.href = '/products?param=COLD_CHAIN_EQUIPMENT';
}
else if(item.title=='DENTAL INSTRUMENTS'){
  window.location.href = '/products?param=DENTAL_INSTRUMENTS';
}
else if(item.title=='DIAGNOSTIC EQUIPMENTS'){
  window.location.href = '/products?param=DIAGNOSTIC_EQUIPMENTS';
}
else if(item.title=='HEALTH & YOGA PRODUCTS'){
  window.location.href = '/products?param=HEALTH_YOGA_PRODUCTS';
}
else if(item.title=='HEIGHT & WEIGHT SCALES'){
  window.location.href = '/products?param=HEIGHT_WEIGHT_SCALES';
}
else if(item.title=='HOSPITAL DISPOSABLE PRODUCTS'){
  window.location.href = '/products?param=HOSPITAL_DISPOSABLE_PRODUCTS';
}
else if(item.title=='HOSPITAL SCRUBS & LINENS'){
  window.location.href = '/products?param=HOSPITAL_SCRUBS_LINENS';
}
else if(item.title=='HOSPITAL FURNITURE'){
  window.location.href = '/products?param=HOSPITAL_FURNITURE';
}
else if(item.title=='HOSPITAL HOLLOWARE'){
  window.location.href = '/products?param=HOSPITAL_HOLLOWARE';
}
else if(item.title=='INFANT CARE EQUIPMENT'){
  window.location.href = '/products?param=INFANT_CARE_EQUIPMENT';
}
else if(item.title=='LABORATORY EQUIPMENTS'){
  window.location.href = '/products?param=LABORATORY_EQUIPMENTS';
}
else if(item.title=='LABORATORY PLASTICWARE'){
  window.location.href = '/products?param=LABORATORY_PLASTICWARE';
}
else if(item.title=='MISCELLANEOUS MEDICAL PRODUCTS'){
  window.location.href = '/products?param=MISCELLANEOUS_MEDICAL_PRODUCTS';
}
else if(item.title=='MEDICAL RUBBER PRODUCTS'){
  window.location.href = '/products?param=MEDICAL_RUBBER_PRODUCTS';
}
else if(item.title=='MEDICAL DISPOSABLE'){
  window.location.href = '/products?param=MEDICAL_DISPOSABLE';
}
else if(item.title=='OBSTETRICS & GYNAECOLOGY'){
  window.location.href = '/products?param=OBSTETRICS_GYNAECOLOGY';
}
else if(item.title=='ORTHOPAEDIC AIDS & REHAB PRODUCTS'){
  window.location.href = '/products?param=ORTHOPAEDIC_AIDS_REHAB_PRODUCTS';
}
else if(item.title=='ORTHOPEDIC INSTRUMENTS'){
  window.location.href = '/products?param=ORTHOPEDIC_INSTRUMENTS';
}
else if(item.title=='OT EQUIPMENT'){
  window.location.href = '/products?param=OT_EQUIPMENT';
}
else if(item.title=='PHYSIOTHERAPY PRODUCTS'){
  window.location.href = '/products?param=PHYSIOTHERAPY_PRODUCTS';
}
else if(item.title=='SUCTION MACHINES & UNITS'){
  window.location.href = '/products?param=SUCTION_MACHINES_UNITS';
}
else if(item.title=='SURGICAL INSTRUMENTS'){
  window.location.href = '/products?param=SURGICAL_INSTRUMENTS';
}






  }

  const [showAll, setShowAll] = useState(false);
  const handleClick = () => {
    setShowAll(true);
    all.current.style.display = showAll ? 'block' : 'none';
   
  };

  const [why,setWhy]=useState(deskWhy)
  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.matchMedia('(max-width:500px)').matches;
      if(isMobileView){
      setWhy(mobWhy);
      }
      else{
        setWhy(deskWhy);
      }
    };
  
    // Add event listener for resize
    window.addEventListener('resize', handleResize);
  
    // Initial check on component mount
    handleResize();
  
    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
   
    };
  }, []);
  

  return (
    <div className='home'>
      <Helmet>
        <title>Guru Surgicals | Manufacture And Supply Surgical Products</title>
        <meta name="description" content="Guru Surgicals is a trusted provider of high-quality surgical instruments and medical equipment. We offer a wide range of surgical instruments for various medical procedures, including general surgery, orthopedics, gynecology, and more. Our products are designed to meet the needs of healthcare professionals, providing precision, durability, and reliability. Visit us for all your surgical instrument needs." />
        <meta name="keywords" content="surgical instruments, medical equipment, high-quality surgical instruments, surgical instruments supplier, surgical equipment, general surgery instruments, orthopedic instruments, gynecology instruments, healthcare instruments, medical instrument provider" />
        <meta name="author" content="Rithish Abinav T" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        
        {/* Open Graph meta tags (for social media sharing) */}
        <meta property="og:title" content="Guru Surgicals | Manufacture And Supply Surgical Products" />
        <meta property="og:description" content="Guru Surgicals is a trusted provider of high-quality surgical instruments and medical equipment. We offer a wide range of surgical instruments for various medical procedures, including general surgery, orthopedics, gynecology, and more. Our products are designed to meet the needs of healthcare professionals, providing precision, durability, and reliability. Visit us for all your surgical instrument needs." />
        <meta property="og:image" content='http://gurusurgicals.com/static/media/Logo.9c55885c8bc49c394674.jpeg' />
        <meta property="og:url" content='http://gurusurgicals.com/' />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card meta tags (for Twitter sharing) */}
        <meta name="twitter:title" content="Guru Surgicals | Manufacture And Supply Surgical Products" />
        <meta name="twitter:description" content="Guru Surgicals is a trusted provider of high-quality surgical instruments and medical equipment. We offer a wide range of surgical instruments for various medical procedures, including general surgery, orthopedics, gynecology, and more. Our products are designed to meet the needs of healthcare professionals, providing precision, durability, and reliability. Visit us for all your surgical instrument needs." />
        <meta name="twitter:image" content='http://gurusurgicals.com/static/media/Logo.9c55885c8bc49c394674.jpeg' />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="for_seo">
        <h1>Guru Surgicals</h1>
        <h1>Guru Group</h1>
        <h1>Guru Integrated Services</h1>
        <h1>Guru Surgical</h1>
        <h1>Guru Surgicals</h1>
        <h1>Guru Group</h1>
        <h1>Guru Integrated Services</h1>
        <h1>Guru Surgical</h1>
      </div>
      <div className="whatsapp">
        <a href='whatsapp://send?phone=9176625631" target="_blank"'>   <img alt='gurusurgicals' loading="lazy"src={require('../Assets/whatsap -icon.png')}/> </a>
      </div>
      <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active" data-bs-interval="4000">
              <img alt='gurusurgicals' loading="lazy"src={require('../Assets/banner/banner11.webp')} className="d-block w-100"  />
          </div>
          <div className="carousel-item" data-bs-interval="4000">
              <img alt='gurusurgicals' loading="lazy"src={require('../Assets/banner/banner22.webp')} className="d-block w-100"  />
          </div>
          <div className="carousel-item" data-bs-interval="4000">
              <img  alt='gurusurgicals' loading="lazy"src={require('../Assets/banner/banner33.webp')} className="d-block w-100"  />
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>


      <div className="badges">

        <div className="badge">
            <img loading="lazy"className='icon' src={require('../Assets/b1.png')} />
          <h4>Good quality products</h4>
          <p>at an economical rate to our customer</p>
          <a href=''>   <img loading="lazy"className='arrow' src={require('../Assets/arrow.png')} /> </a>
        </div>

        <div className="badge">
            <img loading="lazy"className='icon' src={require('../Assets/b2.png')} />
          <h4>Industry-leading player</h4>
          <p>In Surgical Industry</p>
          <a href=''>   <img loading="lazy"className='arrow' src={require('../Assets/arrow.png')} /> </a>
        </div>

        <div className="badge">
            <img loading="lazy"className='icon' src={require('../Assets/b3.png')} />
          <h4>Quality, Cost and Timelines</h4>
          <p>Our quality products are competitively priced.</p>
          <a href=''>   <img loading="lazy"className='arrow' src={require('../Assets/arrow.png')} /> </a>
        </div>

      </div>


      <div className="product_categories">

        <h3>PRODUCT CATEGORIES </h3>

        {showAll?
          ProductCategories.map((item) => (
            <div className="_product">
                <img loading="lazy"src={item.image} width='100%' alt='gurusurgicals' />
              <h4>{item.title}</h4>
              <button onClick={() => viewProduct(item)}>View Products</button>
            </div>
          ))
          :
          ProductCategories.slice(0,6).map((item) => (
            <div className="_product">
                <img loading="lazy"src={item.image} width='100%' alt='gurusurgicals' />
              <h4>{item.title}</h4>
              <button onClick={() => viewProduct(item)}>View Products</button>
            </div>
          ))
        }
      </div>
      <div ref={all} className='allcategory_btn'>   <button   onClick={handleClick}>View all Categories</button> </div>


      <div className="about_us">
        <div className="about_container">
          <h4>About us</h4>
          <p>Welcome to Guru Surgicals, your trusted source for high-quality surgical items. We specialize in providing top-of-the-line surgical instruments, supplies, and equipment to medical professionals and healthcare facilities. With our extensive range of products and commitment to excellence, we ensure that you have access to the tools you need to deliver exceptional patient care. Shop with us today and experience the difference in quality and service.</p>
          <a href='/about'> <button>Learn  More</button> </a>
        </div>
      </div>


      <div className="product_categories">

        <h3>OUR PRODUCTS </h3>

        {
          Ourproduct.map((item) => (
            <div className="_product our_products">
                <img loading="lazy"src={item.ProductImage} width='100%' alt='gurusurgicals' />
              <h4>{item.ProductName}</h4>
              {/* <button>View Products</button> */}
            </div>
          ))
        }
        <a id='viewmore_btn' href='/products?param=HOSPITAL_DISPOSABLE_PRODUCTS'> <button className='view_more'>View More Products</button> </a>
      </div>


      <div className="why_choose">
      <h3>Why Choose Guru Surgicals ?</h3>

        <div className="image">
            <img loading="lazy"src={why} width='100%'/>
        </div>
        <div className="content">
          <p><span> Extensive Product Range :</span> Guru Surgicals offers a comprehensive range of surgical instruments, equipment, and supplies. Whether you need basic surgical tools or specialized equipment for a specific medical procedure, Guru Surgicals can provide you with a diverse selection to meet your requirements.</p>
       <p><span> Superior Quality :</span> Quality is of paramount importance when it comes to surgical instruments and supplies. Guru Surgicals is committed to delivering products of the highest quality to ensure optimal performance and safety. They source their products from reputable manufacturers and rigorously inspect them to maintain stringent quality standards.</p>
       <p><span> Reliability and Durability :</span> Surgical instruments need to withstand rigorous use and frequent sterilization processes. Guru Surgicals understands the importance of reliability and durability in surgical tools. By choosing their products, you can have confidence that the instruments will perform consistently, delivering accurate results and longevity.</p>
        <p><span>Customer Satisfaction :</span>  Guru Surgicals places great emphasis on customer satisfaction. Their knowledgeable and friendly staff are dedicated to assisting customers in finding the right products and addressing any concerns or queries promptly. Their commitment to excellent customer service ensures a positive experience throughout your interactions with them.</p>
        </div>
        
      </div>

    </div>
  );
}



// about us content for surgiacals item selling for website