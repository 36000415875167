const HOSPITAL_DISPOSABLE_PRODUCTS=[

    {
        ProductName: "Disposable Gown and Scrubs",
        ProductImage: require('../Assets/Products/HOSPITAL DISPOSABLE PRODUCTS/hospital-uniforms-dresses.jpg')
    },
    {
        ProductName: "Disposable Face Mask",
        ProductImage: require('../Assets/Products/HOSPITAL DISPOSABLE PRODUCTS/disposable-face-mask.jpg')
    },    {
        ProductName: "Disposable Cap",
        ProductImage: require('../Assets/Products/HOSPITAL DISPOSABLE PRODUCTS/disposable-cap.jpg')
    },    {
        ProductName: "Disposable Gloves",
        ProductImage: require('../Assets/Products/HOSPITAL DISPOSABLE PRODUCTS/disposable-gloves.jpg')
    },    {
        ProductName: "Shoe & Boot Covers",
        ProductImage: require('../Assets/Products/HOSPITAL DISPOSABLE PRODUCTS/shoe-and-boot-covers.jpg')
    },    {
        ProductName: "Hospital Aprons",
        ProductImage: require('../Assets/Products/HOSPITAL DISPOSABLE PRODUCTS/hospital-aprons.jpg')
    },

]

export default HOSPITAL_DISPOSABLE_PRODUCTS;