const AUTOCLAVE_STERILIZER=[

{
    ProductName: "Portable Autoclaves",
    ProductImage: require('../Assets/Products/AUTOCLAVE & STERILIZER/portable-autoclaves.jpg')
},
{
    ProductName: "Vertical and Horizontal Autoclaves",
    ProductImage: require('../Assets/Products/AUTOCLAVE & STERILIZER/vertical-horizontal-autoclaves.jpg')
},{
    ProductName: "Dental Autoclaves",
    ProductImage: require('../Assets/Products/AUTOCLAVE & STERILIZER/dental-autoclaves.jpg')
},{
    ProductName: "Tattoo Autoclaves",
    ProductImage: require('../Assets/Products/AUTOCLAVE & STERILIZER/tattoo-autoclaves.jpg')
},{
    ProductName: "Ultraviolet Chamber",
    ProductImage: require('../Assets/Products/AUTOCLAVE & STERILIZER/ultraviolet-chamber.jpg')
},{
    ProductName: "Instrument Sterilizer",
    ProductImage: require('../Assets/Products/AUTOCLAVE & STERILIZER/instrument-sterilizer.jpg')
},{
    ProductName: "Formalin Chamber",
    ProductImage: require('../Assets/Products/AUTOCLAVE & STERILIZER/formalin-chamber.jpg')
},{
    ProductName: "Dressing Drums",
    ProductImage: require('../Assets/Products/AUTOCLAVE & STERILIZER/dressing-drum.jpg')
},{
    ProductName: "Stainless Steel Cidex Tray",
    ProductImage: require('../Assets/Products/AUTOCLAVE & STERILIZER/cidex-tray.jpg')
},{
    ProductName: "Sterilization Cassette",
    ProductImage: require('../Assets/Products/AUTOCLAVE & STERILIZER/sterilization-cassette.jpg')
},

]

export default AUTOCLAVE_STERILIZER;