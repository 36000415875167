const ANAESTHESIA_EQUIPMENTS = [

    {
        ProductName: "Artificial/Manual Resuscitators",
        ProductImage: require('../Assets/Products/ANAESTHESIA EQUIPMENTS/artificial-resuscitator.jpg')
    },

    {
        ProductName: "Bain Circuit",
        ProductImage: require('../Assets/Products/ANAESTHESIA EQUIPMENTS/bain-circuit.jpg')
    },
    {
        ProductName: "Laryngoscope",
        ProductImage: require('../Assets/Products/ANAESTHESIA EQUIPMENTS/laryngoscopes.jpg')
    },
    {
        ProductName: "Emergency FIRST AID Kit",
        ProductImage: require('../Assets/Products/ANAESTHESIA EQUIPMENTS/emergency-first-aid-kit.jpg')
    },
    {
        ProductName: "Anesthesia Machine",
        ProductImage: require('../Assets/Products/ANAESTHESIA EQUIPMENTS/anesthesia-machine.jpg')
    },
    {
        ProductName: "Anaesthesia Face Masks",
        ProductImage: require('../Assets/Products/ANAESTHESIA EQUIPMENTS/anaesthesia-face-masks.jpg')
    },
    {
        ProductName: "Air Cushion Face Masks",
        ProductImage: require('../Assets/Products/ANAESTHESIA EQUIPMENTS/air-cushion-face-masks.jpg')
    },
    {
        ProductName: "CPR Mask",
        ProductImage: require('../Assets/Products/ANAESTHESIA EQUIPMENTS/cpr-mask.jpg')
    },
    {
        ProductName: "Guedel Airways",
        ProductImage: require('../Assets/Products/ANAESTHESIA EQUIPMENTS/guedel-airways.jpg')
    },
    {
        ProductName: "Mouth Opener",
        ProductImage: require('../Assets/Products/ANAESTHESIA EQUIPMENTS/mouth-opener.jpg')
    },
    {
        ProductName: "Oxygen Cylinder & Regulators",
        ProductImage: require('../Assets/Products/ANAESTHESIA EQUIPMENTS/oxygen-cylinder-regulators.jpg')
    },
    {
        ProductName: "Nebulizer Face Mask",
        ProductImage: require('../Assets/Products/ANAESTHESIA EQUIPMENTS/nebulizer-face-mask.jpg')
    },
    {
        ProductName: "Oxygen Masks",
        ProductImage: require('../Assets/Products/ANAESTHESIA EQUIPMENTS/oxygen-masks.jpg')
    },
    {
        ProductName: "Head Harness",
        ProductImage: require('../Assets/Products/ANAESTHESIA EQUIPMENTS/head-harness.jpg')
    },
    {
        ProductName: "Rebreathing Bags",
        ProductImage: require('../Assets/Products/ANAESTHESIA EQUIPMENTS/rebreathing-bags.jpg')
    },




]

export default ANAESTHESIA_EQUIPMENTS;