import React, { useEffect, useRef, useState } from 'react'
import './Products.css'
import { Helmet } from 'react-helmet';
import logo from "../Assets/Logo.jpg"
import { useLocation } from 'react-router-dom';
import DIAGNOSTIC_EQUIPMENTS from '../ProductDetails/DIAGNOSTIC EQUIPMENTS';
import HOSPITAL_SCRUBS_LINENS from '../ProductDetails/HOSPITAL SCRUBS & LINENS';
import X_RAY_SUPPLIES from '../ProductDetails/X-RAY SUPPLIES';
import ANAESTHESIA_EQUIPMENTS from '../ProductDetails/ANAESTHESIA EQUIPMENTS';
import AUTOCLAVE_STERILIZER from '../ProductDetails/AUTOCLAVE STERILIZER';
import HEIGHT_WEIGHT_SCALES from '../ProductDetails/HEIGHT & WEIGHT SCALES';
import COLD_CHAIN_EQUIPMENT from '../ProductDetails/COLD CHAIN EQUIPMENT';
import SURGICAL_INSTRUMENTS from '../ProductDetails/SURGICAL INSTRUMENTS';
import DENTAL_INSTRUMENTS from '../ProductDetails/DENTAL INSTRUMENTS';
import ORTHOPEDIC_INSTRUMENTS from '../ProductDetails/ORTHOPEDIC INSTRUMENTS';
import HOSPITAL_FURNITURE from '../ProductDetails/HOSPITAL FURNITURE';
import HOSPITAL_HOLLOWARE from '../ProductDetails/HOSPITAL HOLLOWARE';
import MISCELLANEOUS_MEDICAL_PRODUCTS from '../ProductDetails/MISCELLANEOUS MEDICAL PRODUCTS';
import LABORATORY_PLASTICWARE from '../ProductDetails/LABORATORY PLASTICWARE';
import OBSTETRICS_GYNAECOLOGY from '../ProductDetails/OBSTETRICS & GYNAECOLOGY';
import SUCTION_MACHINES_UNITS from '../ProductDetails/SUCTION MACHINES UNITS';
import HEALTH_YOGA_PRODUCTS from '../ProductDetails/HEALTH & YOGA PRODUCTS';
import OT_EQUIPMENT from '../ProductDetails/OT EQUIPMENT';
import INFANT_CARE_EQUIPMENT from '../ProductDetails/INFANT CARE EQUIPMENT';
import MEDICAL_RUBBER_PRODUCTS from '../ProductDetails/MEDICAL RUBBER PRODUCTS';
import ORTHOPAEDIC_AIDS_REHAB_PRODUCTS from '../ProductDetails/ORTHOPAEDIC AIDS & REHAB PRODUCTS';
import LABORATORY_EQUIPMENTS from '../ProductDetails/LABORATORY EQUIPMENTS';
import MEDICAL_DISPOSABLE from '../ProductDetails/MEDICAL DISPOSABLE';
import PHYSIOTHERAPY_PRODUCTS from '../ProductDetails/PHYSIOTHERAPY PRODUCTS';
import HOSPITAL_DISPOSABLE_PRODUCTS from '../ProductDetails/HOSPITAL DISPOSABLE PRODUCTS';
import emailjs from '@emailjs/browser';


export default function Products() {

  const location = useLocation();

  const [data,setData]=useState(DIAGNOSTIC_EQUIPMENTS);
  const[head,setHead]=useState("");
  const[bookedproduct,setBookedproduct]=useState("")
  const[popup,setPopup]=useState(false)
  const[param,setParam]=useState();

  const buy=(item)=>{
    setPopup(true)
console.log(item.ProductName)
setBookedproduct(item.ProductName)
console.log(bookedproduct)
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const lll = params.get('param');
    console.log(lll)
if(lll==='PHYSIOTHERAPY_PRODUCTS'){
  setData(PHYSIOTHERAPY_PRODUCTS)
}
else if(lll==='DIAGNOSTIC_EQUIPMENTS'){
  setData(DIAGNOSTIC_EQUIPMENTS)
}
else if(lll==='HOSPITAL_SCRUBS_LINENS'){
  setData(HOSPITAL_SCRUBS_LINENS)
}
else if(lll==='SURGICAL_INSTRUMENTS'){
  setData(SURGICAL_INSTRUMENTS)
}
else if(lll==='MEDICAL_RUBBER_PRODUCTS'){
  setData(MEDICAL_RUBBER_PRODUCTS)
}
else if(lll==='MEDICAL_DISPOSABLE'){
  setData(MEDICAL_DISPOSABLE)
}
else if(lll==='ANAESTHESIA EQUIPMENTS'){
  setData(ANAESTHESIA_EQUIPMENTS)
}
else if(lll==='AUTOCLAVE_STERILIZER'){
  setData(AUTOCLAVE_STERILIZER)
}
else if(lll==='COLD_CHAIN_EQUIPMENT'){
  setData(COLD_CHAIN_EQUIPMENT)
}
else if(lll==='DENTAL_INSTRUMENTS'){
  setData(DENTAL_INSTRUMENTS)
}
else if(lll==='HEALTH_YOGA_PRODUCTS'){
  setData(HEALTH_YOGA_PRODUCTS)
}
else if(lll==='HEIGHT_WEIGHT_SCALES'){
  setData(HEIGHT_WEIGHT_SCALES)
}
else if(lll==='HOSPITAL_DISPOSABLE_PRODUCTS'){
  setData(HOSPITAL_DISPOSABLE_PRODUCTS)
}
else if(lll==='HOSPITAL_FURNITURE'){
  setData(HOSPITAL_FURNITURE)
}
else if(lll==='HOSPITAL_HOLLOWARE'){
  setData(HOSPITAL_HOLLOWARE)
}
else if(lll==='INFANT_CARE_EQUIPMENT'){
  setData(INFANT_CARE_EQUIPMENT)
}
else if(lll==='LABORATORY_EQUIPMENTS'){
  setData(LABORATORY_EQUIPMENTS)
}
else if(lll==='LABORATORY_PLASTICWARE'){
  setData(LABORATORY_PLASTICWARE)
}
else if(lll==='MISCELLANEOUS_MEDICAL_PRODUCTS'){
  setData(MISCELLANEOUS_MEDICAL_PRODUCTS)
}
else if(lll==='OBSTETRICS_GYNAECOLOGY'){
  setData(OBSTETRICS_GYNAECOLOGY)
}
else if(lll==='ORTHOPAEDIC_AIDS_REHAB_PRODUCTS'){
  setData(ORTHOPAEDIC_AIDS_REHAB_PRODUCTS)
}
else if(lll==='ORTHOPEDIC_INSTRUMENTS'){
  setData(ORTHOPEDIC_INSTRUMENTS)
}
else if(lll==='OT_EQUIPMENT'){
  setData(OT_EQUIPMENT)
}
else if(lll==='PHYSIOTHERAPY_PRODUCTS'){
  setData(PHYSIOTHERAPY_PRODUCTS)
}
else if(lll==='SUCTION_MACHINES_UNITS'){
  setData(SUCTION_MACHINES_UNITS)
}
  }, []);

  useEffect(()=>{
if(data==DIAGNOSTIC_EQUIPMENTS){
  setHead('DIAGNOSTIC EQUIPMENTS')
}
else if(data==HOSPITAL_SCRUBS_LINENS){
  setHead("HOSPITAL SCRUBS & LINENS")
}
else if(data==X_RAY_SUPPLIES){
  setHead('X-RAY SUPPLIES')
}
else if(data==ANAESTHESIA_EQUIPMENTS){
  setHead('ANAESTHESIA EQUIPMENTS')
}
else if(data==AUTOCLAVE_STERILIZER){
  setHead("AUTOCLAVE & STERILIZER")
}
else if(data==HEIGHT_WEIGHT_SCALES){
  setHead('HEIGHT & WEIGHT SCALES')
}
else if(data==COLD_CHAIN_EQUIPMENT){
  setHead('COLD CHAIN EQUIPMENT')
}
else if(data==SURGICAL_INSTRUMENTS){
  setHead('SURGICAL INSTRUMENTS')
}
else if(data==DENTAL_INSTRUMENTS){
  setHead('DENTAL INSTRUMENTS')
}
else if(data==ORTHOPEDIC_INSTRUMENTS){
  setHead('ORTHOPEDIC INSTRUMENTS')
}
else if(data==HOSPITAL_FURNITURE){
  setHead('HOSPITAL FURNITURE')
}
else if(data==HOSPITAL_HOLLOWARE){
  setHead('HOSPITAL HOLLOWARE')
}
else if(data==MISCELLANEOUS_MEDICAL_PRODUCTS){
  setHead('MISCELLANEOUS MEDICAL PRODUCTS')
}
else if(data==LABORATORY_PLASTICWARE){
  setHead('LABORATORY PLASTICWARE')
}
else if(data==OBSTETRICS_GYNAECOLOGY){
  setHead('OBSTETRICS & GYNAECOLOGY')
}
else if(data==SUCTION_MACHINES_UNITS){
  setHead('SUCTION MACHINES & UNITS')
}
else if(data==HEALTH_YOGA_PRODUCTS){
  setHead('HEALTH & YOGA PRODUCTS')
}
else if(data==OT_EQUIPMENT){
  setHead('OT EQUIPMENT')
}
else if(data==INFANT_CARE_EQUIPMENT){
  setHead('INFANT CARE EQUIPMENT')
}
else if(data==MEDICAL_RUBBER_PRODUCTS){
  setHead('MEDICAL RUBBER PRODUCTS')
}
else if(data==ORTHOPAEDIC_AIDS_REHAB_PRODUCTS){
  setHead('ORTHOPAEDIC AIDS & REHAB PRODUCTS')
}
else if(data==LABORATORY_EQUIPMENTS){
  setHead('LABORATORY EQUIPMENTS')
}
else if (data==MEDICAL_DISPOSABLE){
  setHead('MEDICAL DISPOSABLE')
}
else if( data==PHYSIOTHERAPY_PRODUCTS ){
  setHead('PHYSIOTHERAPY PRODUCTS')
}
else if(data==HOSPITAL_DISPOSABLE_PRODUCTS){
  setHead('HOSPITAL DISPOSABLE PRODUCTS')
}
  },[data])

 
useEffect(()=>{
  window.location.hash = '';
  window.history.replaceState({}, document.title, window.location.pathname);
})

const [toggle,setToggle]=useState(false)
const showSection = () => {
  setToggle(!toggle)
  var dropdown = document.querySelectorAll('.sidebar ul li');
  if (window.innerWidth < 500) {
    if(toggle){

    dropdown.forEach((item) => {
      item.style.display = "block";
    });
  }
  else{
    dropdown.forEach((item) => {
      item.style.display = "none";
    });
  }
  }
  
};

useEffect(() => {
  showSection()
},[data]);



const form = useRef();

const[name,setName]=useState('');
const[phone,setPhone]=useState('');
const[quantity,setQuantity]=useState("")
const[err,setErr]=useState("")


const sendEmail = (e) => {
  e.preventDefault();

  if(name=="" || phone=="" || quantity==""){
setErr("Please Fill All the Fields")
  }
  else{

 
    setErr("")
const btn = document.getElementById('btn');
const originalButtonText = btn.value;

emailjs.sendForm('service_pxrkyxj', 'template_2jkuipn', form.current, 'DIa2gSgKlZOdU18RA')
  .then((result) => {
    console.log(result.text);
    btn.value = 'Sending';
    console.log('sent');

    setTimeout(() => {
      btn.value ='Enquiry Sent Successfully';
      setTimeout(() => {
        if(btn.value=='Enquiry Sent Successfully'){
          setPopup(false)        
        }
        setName("")
        setPhone("")
        setQuantity("")
      }, 2000);
   
    }, 4000);
  
  })
  .catch((error) => {
    console.log(error.text);
  });

  }

};

  return (
    <div className='product_page'>
         <Helmet>
        <title>Guru Surgicals | Manufacture And Supply Surgical Products</title>
        <meta name="description" content="Guru Surgicals is a trusted provider of high-quality surgical instruments and medical equipment. We offer a wide range of surgical instruments for various medical procedures, including general surgery, orthopedics, gynecology, and more. Our products are designed to meet the needs of healthcare professionals, providing precision, durability, and reliability. Visit us for all your surgical instrument needs." />
        <meta name="keywords" content="surgical instruments, medical equipment, high-quality surgical instruments, surgical instruments supplier, surgical equipment, general surgery instruments, orthopedic instruments, gynecology instruments, healthcare instruments, medical instrument provider" />
        <meta name="author" content="Rithish Abinav T" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        
        {/* Open Graph meta tags (for social media sharing) */}
        <meta property="og:title" content="Guru Surgicals | Manufacture And Supply Surgical Products" />
        <meta property="og:description" content="Guru Surgicals is a trusted provider of high-quality surgical instruments and medical equipment. We offer a wide range of surgical instruments for various medical procedures, including general surgery, orthopedics, gynecology, and more. Our products are designed to meet the needs of healthcare professionals, providing precision, durability, and reliability. Visit us for all your surgical instrument needs." />
        <meta property="og:image" content='http://gurusurgicals.com/static/media/Logo.9c55885c8bc49c394674.jpeg' />
        <meta property="og:url" content='http://gurusurgicals.com/' />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card meta tags (for Twitter sharing) */}
        <meta name="twitter:title" content="Guru Surgicals | Manufacture And Supply Surgical Products" />
        <meta name="twitter:description" content="Guru Surgicals is a trusted provider of high-quality surgical instruments and medical equipment. We offer a wide range of surgical instruments for various medical procedures, including general surgery, orthopedics, gynecology, and more. Our products are designed to meet the needs of healthcare professionals, providing precision, durability, and reliability. Visit us for all your surgical instrument needs." />
        <meta name="twitter:image" content='http://gurusurgicals.com/static/media/Logo.9c55885c8bc49c394674.jpeg' />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="for_seo">
        <h1>Guru Surgicals</h1>
        <h1>Guru Group</h1>
        <h1>Guru Integrated Services</h1>
        <h1>Guru Surgical</h1>
        <h1>Guru Surgicals</h1>
        <h1>Guru Group</h1>
        <h1>Guru Integrated Services</h1>
        <h1>Guru Surgical</h1>
      </div>
      <div className="banner">
        <h2>Products</h2>
      </div>

<div id='top'></div>
<div className="product_container">

<div className="sidebar">
<ul>
    <h5 onClick={showSection}>CATEGORIES <span id='productDropdownIcon'><i class="fa-solid fa-caret-down"></i></span> </h5>
       
  <li onClick={() => setData(DIAGNOSTIC_EQUIPMENTS)}><a href='#top'    title="Diagnostic Equipments">› Diagnostic Equipments</a></li>
       
  <li onClick={() => setData(HOSPITAL_SCRUBS_LINENS)}><a href='#top'     title="Hospital Scrubs & Linens">› Hospital Scrubs & Linens</a></li>
       
  <li onClick={() => setData(X_RAY_SUPPLIES)}><a href='#top'    title="X-Ray Supplies">› X-Ray Supplies</a></li>
       
  <li onClick={() => setData(ANAESTHESIA_EQUIPMENTS)}><a href='#top'    title="Anaesthesia Equipments">› Anaesthesia Equipments</a></li>
       
  <li onClick={() => setData(AUTOCLAVE_STERILIZER)}><a href='#top'    title="Autoclave & Sterilizer">› Autoclave & Sterilizer</a></li>
       
  <li onClick={() => setData(HEIGHT_WEIGHT_SCALES)}><a href='#top'    title="Height & Weight Scales">› Height & Weight Scales</a></li>
       
  <li onClick={() => setData(COLD_CHAIN_EQUIPMENT)}><a href='#top'    title="Cold Chain Equipment">› Cold Chain Equipment</a></li>
       
  <li  onClick={() => setData(SURGICAL_INSTRUMENTS)}><a href='#top'   title="Surgical Instruments">› Surgical Instruments</a></li>
       
  <li onClick={() => setData(DENTAL_INSTRUMENTS)}><a href='#top'    title="Dental Instruments">› Dental Instruments</a></li>
       
  <li onClick={() => setData(ORTHOPEDIC_INSTRUMENTS)}><a href='#top'    title="Orthopedic Instruments">› Orthopedic Instruments</a></li>
       
  <li onClick={() => setData(HOSPITAL_FURNITURE)}><a href='#top'    title="Hospital Furniture">› Hospital Furniture</a></li>
       
  <li onClick={() => setData(HOSPITAL_HOLLOWARE)}><a href='#top'    title="Hospital Holloware">› Hospital Holloware</a></li>
       
  <li onClick={() => setData(MISCELLANEOUS_MEDICAL_PRODUCTS)}><a href='#top'    title="Miscellaneous Medical Products">› Miscellaneous Medical Products</a></li>
       
  <li onClick={() => setData(LABORATORY_PLASTICWARE)}><a href='#top'    title="Laboratory Plasticware">› Laboratory Plasticware</a></li>
       
  <li onClick={() => setData(OBSTETRICS_GYNAECOLOGY)}><a href='#top'    title="Obstetrics & Gynaecology">› Obstetrics & Gynaecology</a></li>
       
  <li onClick={() => setData(SUCTION_MACHINES_UNITS)}><a href='#top'    title="Suction Machines & Units">› Suction Machines & Units</a></li>
       
  <li onClick={() => setData(HEALTH_YOGA_PRODUCTS)}><a href='#top'    title="Health & Yoga Products">› Health & Yoga Products</a></li>
       
  <li onClick={() => setData(OT_EQUIPMENT)}><a href='#top'    title="OT Equipment">› OT Equipment</a></li>
       
  <li onClick={() => setData(INFANT_CARE_EQUIPMENT)}><a href='#top'    title="Infant Care Equipment">› Infant Care Equipment</a></li>
       
  <li onClick={() => setData(MEDICAL_RUBBER_PRODUCTS)}><a href='#top'    title="Medical Rubber Products">› Medical Rubber Products</a></li>
       
  <li onClick={() => setData(ORTHOPAEDIC_AIDS_REHAB_PRODUCTS)}><a href='#top'    title="Orthopaedic Aids & Rehab Products">› Orthopaedic Aids & Rehab Products</a></li>
       
  <li onClick={() => setData(LABORATORY_EQUIPMENTS)}><a href='#top'    title="Laboratory Equipments">› Laboratory Equipments</a></li>
       
  <li onClick={() => setData(MEDICAL_DISPOSABLE)}><a href='#top'    title="Medical Disposable">› Medical Disposable</a></li>
       
  <li onClick={() => setData(PHYSIOTHERAPY_PRODUCTS)}><a href='#top'    title="Physiotherapy Products">› Physiotherapy Products</a></li>
              
  <li onClick={() => setData(HOSPITAL_DISPOSABLE_PRODUCTS)}><a href='#top'  title="Hospital Disposable Products">› Hospital Disposable Products</a></li>
       </ul>
</div>

<div className="main_container">
  <h3>{head}</h3>
<section>
{
  data.map((item)=>(
    <div key={item.ProductName} className="product"> 
      <img loading="lazy"src={item.ProductImage} alt='gurusurgicals'/>  
     <p>{item.ProductName}</p>
     <button onClick={()=>buy(item)}>Buy Now</button>
     </div>

  ))
}
</section>
</div>





</div>

{ popup && 
<div className="booking_container">
  <div className="dummy"  onClick={()=>setPopup(false)}></div>
  <form className="booking_box"  ref={form} onSubmit={sendEmail}>
  <i class="fa-regular fa-circle-xmark" onClick={()=>setPopup(false)}></i>
  <input type='text' placeholder='Enter Your Name' value={name} onChange={(e)=>setName(e.target.value)} name='Name'/>
  <input type='text' maxLength={10} value={phone} onChange={(e)=>setPhone(e.target.value)} placeholder='Enter Your Mobile No' name='Number'/>
  <input type='text' value={bookedproduct} readOnly="readonly" name='Product' />
  <input type='Number' min="1" value={quantity} onChange={(e)=>setQuantity(e.target.value)} placeholder='Select Quantity' name='Quantity'/>
 <p className="error">{err}</p>
  <input id='btn' type="submit" value="Send" />
</form>

</div>
}


    </div>
  )
}
