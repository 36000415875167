const ORTHOPAEDIC_AIDS_REHAB_PRODUCTS=[
    {
        ProductName: "Cervical Splints",
        ProductImage: require('../Assets/Products/ORTHOPAEDIC AIDS & REHAB PRODUCTS/cervical-splints.jpg')
    },
    {
        ProductName: "Back Splints",
        ProductImage: require('../Assets/Products/ORTHOPAEDIC AIDS & REHAB PRODUCTS/back-splints.jpg')
    }, {
        ProductName: "Abdominal Splint",
        ProductImage: require('../Assets/Products/ORTHOPAEDIC AIDS & REHAB PRODUCTS/abdominal-splint.jpg')
    }, {
        ProductName: "Wrist & Elbow Splints",
        ProductImage: require('../Assets/Products/ORTHOPAEDIC AIDS & REHAB PRODUCTS/wrist-elbow-splints.jpg')
    }, {
        ProductName: "Knee Calf & Ankle Splints",
        ProductImage: require('../Assets/Products/ORTHOPAEDIC AIDS & REHAB PRODUCTS/knee-calf-ankle-splints.jpg')
    }, {
        ProductName: "Cervical Traction Kit",
        ProductImage: require('../Assets/Products/ORTHOPAEDIC AIDS & REHAB PRODUCTS/cervical-traction-kit-spares.jpg')
    }, {
        ProductName: "Pelvic Traction Kit",
        ProductImage: require('../Assets/Products/ORTHOPAEDIC AIDS & REHAB PRODUCTS/pelvic-traction-kit-spares.jpg')
    }, {
        ProductName: "Fracture Aids",
        ProductImage: require('../Assets/Products/ORTHOPAEDIC AIDS & REHAB PRODUCTS/fracture-aids.jpg')
    }, {
        ProductName: "General Aids",
        ProductImage: require('../Assets/Products/ORTHOPAEDIC AIDS & REHAB PRODUCTS/general-aids.jpg')
    }, {
        ProductName: "Walking Sticks or Walking Canes",
        ProductImage: require('../Assets/Products/ORTHOPAEDIC AIDS & REHAB PRODUCTS/walking-sticks.jpg')
    }, {
        ProductName: "Walkers",
        ProductImage: require('../Assets/Products/ORTHOPAEDIC AIDS & REHAB PRODUCTS/walkers.jpg')
    }, {
        ProductName: "Underarm Crutches",
        ProductImage: require('../Assets/Products/ORTHOPAEDIC AIDS & REHAB PRODUCTS/underarm-crutches.jpg')
    },  
]

export default ORTHOPAEDIC_AIDS_REHAB_PRODUCTS;