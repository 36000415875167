const COLD_CHAIN_EQUIPMENT=[

{
    ProductName: "Vaccine Carrier Box",
    ProductImage: require('../Assets/Products/COLD CHAIN EQUIPMENT/vaccine-carrier-box.jpg')
},
{
    ProductName: "Cold Box",
    ProductImage: require('../Assets/Products/COLD CHAIN EQUIPMENT/cold-box.jpg')
},
{
    ProductName: "Ice Pack",
    ProductImage: require('../Assets/Products/COLD CHAIN EQUIPMENT/ice-pack.jpg')
},

]

export default COLD_CHAIN_EQUIPMENT;