const X_RAY_SUPPLIES=[
    {
        ProductName: "X-Ray View Box",
        ProductImage: require('../Assets/Products/X-RAY SUPPLIES/x-ray-view-box.jpg')
    },
    {
        ProductName: "Lead Aprons",
        ProductImage: require('../Assets/Products/X-RAY SUPPLIES/lead-aprons.jpg')
    },
    {
        ProductName: "Thyroid Collar/Shield",
        ProductImage: require('../Assets/Products/X-RAY SUPPLIES/thyroid-collars.jpg')
    }, {
        ProductName: "Lead Gloves",
        ProductImage: require('../Assets/Products/X-RAY SUPPLIES/lead-gloves.jpg')
    }, {
        ProductName: "Lead Goggles",
        ProductImage: require('../Assets/Products/X-RAY SUPPLIES/x-ray-lead-goggles.jpg')
    }, {
        ProductName: "Gonad & Ovarian Shields",
        ProductImage: require('../Assets/Products/X-RAY SUPPLIES/gonad-and-ovarian-shields.jpg')
    }, {
        ProductName: "Radiation Protection Head Shield",
        ProductImage: require('../Assets/Products/X-RAY SUPPLIES/radiation-protection-head-shield.jpg')
    }, {
        ProductName: "Mobile Lead Barrier",
        ProductImage: require('../Assets/Products/X-RAY SUPPLIES/mobile-lead-barrier.jpg')
    }, {
        ProductName: "X-Ray Hangers",
        ProductImage: require('../Assets/Products/X-RAY SUPPLIES/x-ray-hangers.jpg')
    }, {
        ProductName: "X-Ray Cassettes",
        ProductImage: require('../Assets/Products/X-RAY SUPPLIES/x-ray-cassettes.jpg')
    }
    
   

]

export default X_RAY_SUPPLIES;