import React from 'react'
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css'
import Home from './Home/Home'
import About from './About/About'
import Products from './Products/Products'
import Contact from './Contact/Contact'
import Header from './Header/Header';
import Footer from './Footer/Footer';

export default function App() {
  return (
    <BrowserRouter>
          <Header/>

    <Routes>
    <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="products" element={<Products />} />
    </Routes>
    <Footer/>
  </BrowserRouter>
  )
}
