const HOSPITAL_FURNITURE=[
{
    ProductName: "Hospital Beds",
    ProductImage: require('../Assets/Products/HOSPITAL FURNITURE/hospital-furniture.jpg')
},
{
    ProductName: "Hospital Ward Furniture",
    ProductImage: require('../Assets/Products/HOSPITAL FURNITURE/hospital-ward-furniture.jpg')
},
{
    ProductName: "Wheelchairs",
    ProductImage: require('../Assets/Products/HOSPITAL FURNITURE/wheel-chair.jpg')
},
{
    ProductName: "Ambulance Stretchers",
    ProductImage: require('../Assets/Products/HOSPITAL FURNITURE/stretchers.jpg')
},{
    ProductName: "Hospital Trolleys",
    ProductImage: require('../Assets/Products/HOSPITAL FURNITURE/hospital-trolleys.jpg')
},{
    ProductName: "Examination & Delivery Tables",
    ProductImage: require('../Assets/Products/HOSPITAL FURNITURE/examination-delivery-tables.jpg')
},{
    ProductName: "Blood Donor Couch & Chairs",
    ProductImage: require('../Assets/Products/HOSPITAL FURNITURE/blood-donor-chairs.jpg')
},
]

export default HOSPITAL_FURNITURE;